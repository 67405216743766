@import '~antd/dist/antd.less';

.app-container {
  height: 100vh;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-background {
  overflow-y: scroll;
}

.error-message {
  color: red;
  display: flex;
  justify-content: center;
}

.content-header {
  display: flex;
  justify-content: space-between;
  //margin-bottom: 12px;

  .icon {
    margin-right: 8px;
  }
}

.content-form {
  max-width: 700px;
}

.button-group {
  padding-top: 24px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >Button {
    margin-bottom: 8px;
  }
}

.button-group,
.form-button {
  Button {
    width: 100px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.form-button {
  margin-top: 12px;
}

.breadcrumb {
  padding-bottom: 24px !important;
}

.ant-row-style {
  cursor: pointer;
}

.ant-row-style1 {
  cursor: pointer;
  background: #8db600 !important;
}

.app-form-container {
  max-width: 1024px;
  padding: 28px;
  margin: 20px auto;
  border-radius: 5px;
  background-color: white;

  .app-form-horizontal-fields {
    display: flex;

    >div {
      flex: 1;
    }

    >div:not(:last-child) {
      padding-right: 16px;
    }
  }

  @media (max-width: 768px) {
    max-width: 680px;
    margin-top: 0;
  }

  @media (max-width: 576px) {
    margin: auto;

    .app-form-horizontal-fields {
      flex-direction: column;

      >div:not(:last-child) {
        padding-right: 0;
      }
    }
  }
}

.app-form-small-container {
  &:extend(.app-form-container);
  max-width: 576px;
}

.ant-pagination {
  text-align: right;
}

.filter-panel {
  margin-bottom: 8px;

  >div {
    margin: 8px !important;
  }

  .filter-button-group {
    width: 100%;

    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1px;
    }

    Button {
      width: 100px !important;
      margin-right: 8px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.long-button {
  min-width: 150px !important;
}

.statistic-container {
  background-color: @primary-2;
  padding: 8px;
  margin-top: 8px;
  display: flex;
  width: 100%;
  overflow-x: auto;

  div {
    padding: 0 16px 0 4px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  span {
    font-weight: bold;
  }
}

.ant-table {
  font-size: 12px;
}

// .ant-table-tbody>tr.ant-table-row:hover>td {
//   background: #046CC1 !important;
// }

// .ant-table-tbody>tr>td.ant-table-cell-row-hover {
//   background: #046CC1 !important;
// }

.ant-descriptions-item-label {
  font-size: 12px;
  padding: 12px 18px !important;
}

.ant-descriptions-item-content {
  font-size: 12px;
  padding: 12px 18px !important;
}

.jodit-react-container {
  margin-bottom: 15px;

  .jodit-workplace {
    min-height: 200px !important;
  }
}

.confirmation-msg-alert-span {
  color: red;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
}

.member-summary-table {
  .ant-table-thead {
    tr:nth-child(1) {

      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(7) {
        color: rgb(16, 193, 16)
      }

      .ant-table-cell:nth-child(4),
      .ant-table-cell:nth-child(8) {
        color: red
      }

      .ant-table-cell:nth-child(5) {
        color: orange
      }

      .ant-table-cell:nth-child(6) {
        color: rgb(149, 43, 43)
      }
    }

    tr:nth-child(2) {

      .ant-table-cell:nth-child(11),
      .ant-table-cell:nth-child(13),
      .ant-table-cell:nth-child(14),
      .ant-table-cell:nth-child(1),
      .ant-table-cell:nth-child(2) {
        color: rgb(16, 193, 16)
      }

      .ant-table-cell:nth-child(12),
      .ant-table-cell:nth-child(15),
      .ant-table-cell:nth-child(16),
      .ant-table-cell:nth-child(3),
      .ant-table-cell:nth-child(4) {
        color: red
      }

      .ant-table-cell:nth-child(5),
      .ant-table-cell:nth-child(6),
      .ant-table-cell:nth-child(7),
      .ant-table-cell:nth-child(8),
      .ant-table-cell:nth-child(9),
      .ant-table-cell:nth-child(10) {
        color: orange
      }
    }
  }
}

.sales-table {
  .ant-table-thead {
      tr:nth-child(1) {
          .ant-table-cell:nth-child(3),
          .ant-table-cell:nth-child(7) {
              color: rgb(16, 193, 16)
          }

          .ant-table-cell:nth-child(4),
          .ant-table-cell:nth-child(8) {
              color: red
          }

          .ant-table-cell:nth-child(5) {
              color: orange
          }

          .ant-table-cell:nth-child(6) {
              color: rgb(149, 43, 43)
          }
      }

      tr:nth-child(2) {
          .ant-table-cell:nth-child(14),
          .ant-table-cell:nth-child(15),
          .ant-table-cell:nth-child(1),
          .ant-table-cell:nth-child(2),
          .ant-table-cell:nth-child(3) {
              color: rgb(16, 193, 16)
          }

          .ant-table-cell:nth-child(12),
          .ant-table-cell:nth-child(13) {
            color: rgb(149, 43, 43)
        }

          .ant-table-cell:nth-child(16),
          .ant-table-cell:nth-child(17),
          .ant-table-cell:nth-child(4),
          .ant-table-cell:nth-child(5) {
              color: red
          }

          .ant-table-cell:nth-child(6),
          .ant-table-cell:nth-child(7),
          .ant-table-cell:nth-child(8),
          .ant-table-cell:nth-child(9),
          .ant-table-cell:nth-child(10),
          .ant-table-cell:nth-child(11) {
              color: orange
          }
      }
  }
}

.profit-desc-item {
  background-color: rgb(119, 178, 200) !important;
}

.retention-clickable {
  text-decoration: underline;
  cursor: pointer;
}

.retention-clickable:hover {
  color: @primary-color
}
@primary-color: #046CC1;@link-color: #1890ff;@background-color-1: lightblue;@background-color-2: gainsboro;@background-color-3: blueviolet;@background-color-4: antiquewhite;@background-color-5: limegreen;@txt-color-1: black;@txt-color-2: white;